import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "./index.css";

// Import the JSON and theme configuration
import { json } from "./json";
import { themeJson } from "./theme";

function SurveyComponent() {
    const defaultLocale = (navigator.language || navigator.userLanguage).split('-')[0];
    const supportedLocales = ['en', 'es', 'de'];
    const initialLocale = supportedLocales.includes(defaultLocale) ? defaultLocale : 'es';

    const [survey, setSurvey] = useState(new Model({ ...json, locale: initialLocale }));
    const [uploadStatus, setUploadStatus] = useState(0); // 0: idle, 1: uploading, 2: complete

    useEffect(() => {
        survey.applyTheme(themeJson);
        survey.locale = initialLocale;
        survey.onComplete.add((sender, options) => {
            const responseData = sender.data;
            const data = JSON.stringify(responseData, null, 3);
            const date = new Date();
            const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;
            const filename = `${formattedDate}-${responseData.userid}.json`;
            uploadToR2(data, filename);
        });
    }, []);

    const changeLanguage = (newLocale) => {
        survey.locale = newLocale;
        survey.render(); // This forces SurveyJS to re-render the survey
    };

    async function uploadToR2(data, filename) {
        setUploadStatus(1); // uploading
        try {
            const url = process.env.REACT_APP_WORKER_URL; // Using the environment variable
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ filename, data })
            });
            if (response.ok) {
                setUploadStatus(2); // complete
            } else {
                throw new Error('Failed to upload');
            }
        } catch (error) {
            console.error('Upload failed:', error);
            setUploadStatus(0); // set back to idle on failure
        }
    }

    return (
        <div>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <button onClick={() => changeLanguage('en')} style={buttonStyle}>
                    English
                </button>
                <button onClick={() => changeLanguage('es')} style={buttonStyle}>
                    Español
                </button>
                <button onClick={() => changeLanguage('de')} style={buttonStyle}>
                    Deutsch
                </button>
            </div>
            <Survey model={survey} />
            <div style={progressBarContainer}>
                <div style={{ ...progressBar, width: `${uploadStatus === 1 ? 50 : uploadStatus === 2 ? 100 : 0}%` }}>
                    {uploadStatus === 1 ? "Saving Please wait..." : uploadStatus === 2 ? "Saving complete!" : ""}
                </div>
            </div>
        </div>
    );
}

const progressBarContainer = {
    height: "20px",
    width: "100%",
    backgroundColor: "#ddd",
    borderRadius: "5px",
    marginTop: "10px"
};

const progressBar = {
    height: "100%",
    backgroundColor: "#4CAF50",
    textAlign: "center",
    lineHeight: "20px",
    color: "white",
    borderRadius: "5px"
};

const buttonStyle = {
    margin: "0 10px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    background: "#f0f0f0",
    color: "#333",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
};

export default SurveyComponent;
