export const themeJson = {
  "backgroundImage": "https://cdn.x4t.com/cards%2Fbackground.jpg",
  "backgroundImageFit": "cover",
  "backgroundImageAttachment": "scroll",
  "backgroundOpacity": 0.6,
  "header": {
    "height": 500,
    "inheritWidthFrom": "survey",
    "textAreaWidth": 400,
    "overlapEnabled": false,
    "backgroundImageOpacity": 1,
    "backgroundImageFit": "cover",
    "logoPositionX": "left",
    "logoPositionY": "top",
    "titlePositionX": "right",
    "titlePositionY": "top",
    "descriptionPositionX": "right",
    "descriptionPositionY": "top"
  },
  "isPanelless": true,
  "cssVariables": {
    "--sjs-general-backcolor": "rgba(56, 10, 83, 1)",
    "--sjs-general-backcolor-dark": "rgba(52, 52, 52, 1)",
    "--sjs-general-backcolor-dim": "#380A53",
    "--sjs-general-backcolor-dim-light": "rgba(93, 32, 126, 1)",
    "--sjs-general-backcolor-dim-dark": "rgba(46, 46, 46, 1)",
    "--sjs-general-forecolor": "rgba(255, 255, 255, 0.78)",
    "--sjs-general-forecolor-light": "rgba(255, 255, 255, 0.42)",
    "--sjs-general-dim-forecolor": "rgba(255, 255, 255, 0.79)",
    "--sjs-general-dim-forecolor-light": "rgba(255, 255, 255, 0.45)",
    "--sjs-primary-backcolor": "#f22ed2",
    "--sjs-primary-backcolor-light": "rgba(NaN, NaN, NaN, 1)",
    "--sjs-primary-backcolor-dark": "rgba(NaN, NaN, NaN, 1)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-base-unit": "10px",
    "--sjs-corner-radius": "8px",
    "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
    "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
    "--sjs-secondary-forecolor": "rgba(48, 48, 48, 1)",
    "--sjs-secondary-forecolor-light": "rgba(48, 48, 48, 0.25)",
    "--sjs-shadow-small": "0px 0px 0px 2px rgba(13, 1, 19, 1)",
    "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.2)",
    "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
    "--sjs-shadow-inner": "0px 0px 0px 2px rgba(13, 1, 19, 1)",
    "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.03)",
    "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.03)",
    "--sjs-border-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-border-default": "rgba(255, 255, 255, 0.25)",
    "--sjs-border-inside": "rgba(255, 255, 255, 0.08)",
    "--sjs-special-red": "rgba(254, 76, 108, 1)",
    "--sjs-special-red-light": "rgba(254, 76, 108, 0.1)",
    "--sjs-special-red-forecolor": "rgba(48, 48, 48, 1)",
    "--sjs-special-green": "rgba(36, 197, 164, 1)",
    "--sjs-special-green-light": "rgba(36, 197, 164, 0.1)",
    "--sjs-special-green-forecolor": "rgba(48, 48, 48, 1)",
    "--sjs-special-blue": "rgba(91, 151, 242, 1)",
    "--sjs-special-blue-light": "rgba(91, 151, 242, 0.1)",
    "--sjs-special-blue-forecolor": "rgba(48, 48, 48, 1)",
    "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
    "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-special-yellow-forecolor": "rgba(48, 48, 48, 1)",
    "--sjs-article-font-xx-large-textDecoration": "none",
    "--sjs-article-font-xx-large-fontWeight": "700",
    "--sjs-article-font-xx-large-fontStyle": "normal",
    "--sjs-article-font-xx-large-fontStretch": "normal",
    "--sjs-article-font-xx-large-letterSpacing": "0",
    "--sjs-article-font-xx-large-lineHeight": "64px",
    "--sjs-article-font-xx-large-paragraphIndent": "0px",
    "--sjs-article-font-xx-large-textCase": "none",
    "--sjs-article-font-x-large-textDecoration": "none",
    "--sjs-article-font-x-large-fontWeight": "700",
    "--sjs-article-font-x-large-fontStyle": "normal",
    "--sjs-article-font-x-large-fontStretch": "normal",
    "--sjs-article-font-x-large-letterSpacing": "0",
    "--sjs-article-font-x-large-lineHeight": "56px",
    "--sjs-article-font-x-large-paragraphIndent": "0px",
    "--sjs-article-font-x-large-textCase": "none",
    "--sjs-article-font-large-textDecoration": "none",
    "--sjs-article-font-large-fontWeight": "700",
    "--sjs-article-font-large-fontStyle": "normal",
    "--sjs-article-font-large-fontStretch": "normal",
    "--sjs-article-font-large-letterSpacing": "0",
    "--sjs-article-font-large-lineHeight": "40px",
    "--sjs-article-font-large-paragraphIndent": "0px",
    "--sjs-article-font-large-textCase": "none",
    "--sjs-article-font-medium-textDecoration": "none",
    "--sjs-article-font-medium-fontWeight": "700",
    "--sjs-article-font-medium-fontStyle": "normal",
    "--sjs-article-font-medium-fontStretch": "normal",
    "--sjs-article-font-medium-letterSpacing": "0",
    "--sjs-article-font-medium-lineHeight": "32px",
    "--sjs-article-font-medium-paragraphIndent": "0px",
    "--sjs-article-font-medium-textCase": "none",
    "--sjs-article-font-default-textDecoration": "none",
    "--sjs-article-font-default-fontWeight": "400",
    "--sjs-article-font-default-fontStyle": "normal",
    "--sjs-article-font-default-fontStretch": "normal",
    "--sjs-article-font-default-letterSpacing": "0",
    "--sjs-article-font-default-lineHeight": "28px",
    "--sjs-article-font-default-paragraphIndent": "0px",
    "--sjs-article-font-default-textCase": "none",
    "--sjs-article-font-xx-large-fontSize": "64px",
    "--sjs-article-font-x-large-fontSize": "48px",
    "--sjs-article-font-large-fontSize": "32px",
    "--sjs-article-font-medium-fontSize": "24px",
    "--sjs-article-font-default-fontSize": "16px",
    "--font-family": "Courier New, monospace",
    "--sjs-editor-background": "rgba(93, 32, 126, 1)",
    "--sjs-editorpanel-hovercolor": "rgba(97, 53, 121, 1)",
    "--sjs-question-background": "rgba(56, 10, 83, 1)",
    "--sjs-questionpanel-backcolor": "rgba(56, 10, 83, 1)",
    "--sjs-questionpanel-hovercolor": "rgba(70, 26, 93, 1)",
    "--sjs-font-editorfont-color": "rgba(238, 226, 229, 1)",
    "--sjs-font-editorfont-placeholdercolor": "rgba(134, 83, 162, 1)",
    "--sjs-font-questiondescription-color": "rgba(134, 83, 162, 1)",
    "--sjs-font-questiontitle-color": "rgba(240, 194, 232, 1)",
    "--sjs-font-size": "16px",
    "--sjs-editorpanel-cornerRadius": "8px",
    "--sjs-font-pagetitle-color": "rgba(242, 46, 210, 1)",
    "--sjs-font-pagetitle-weight": "600",
    "--sjs-font-questiondescription-size": "18px",
    "--sjs-font-editorfont-weight": "600",
    "--sjs-font-questiondescription-weight": "600",
    "--sjs-font-pagedescription-color": "rgba(134, 83, 162, 1)",
    "--sjs-font-pagedescription-weight": "600",
    "--sjs-font-surveytitle-size": "24px",
    "--sjs-font-pagedescription-size": "18px",
    "--sjs-font-questiontitle-size": "18px",
    "--sjs-font-editorfont-size": "18px",
    "--sjs-header-backcolor": "transparent",
    "--sjs-font-headertitle-color": "rgba(255, 51, 102, 1)",
    "--sjs-font-family": "Courier New, monospace",
    "--sjs-font-pagetitle-size": "32px",
    "--sjs-editorpanel-backcolor": "rgba(93, 32, 126, 1)"
  },
  "themeName": "custom",
  "colorPalette": "light",
  "headerView": "advanced"
}
